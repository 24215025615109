<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="My Orders" />
  <!-- Breadcroumbs end -->
  <!-- My Order start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-9">
          <div class="my-account-page-title">
            <h3>My Order</h3>
          </div>
          <div class="order-section">
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Order ID: <b>88523836532315</b><br />
                Order time & date: <small>22:23 Dec. 06 2017</small>
              </div>
              <div class="order-ammount">Order amount: <strong>$35.00</strong></div>
              <div class="delete-order">
                <a href="">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img src="../assets/images/product-1.jpg" alt="" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>Beekeeping Hive</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text.
                    </p>
                  </a>
                </div>
              </div>
              <div class="order-status">
                <h6>Awaiting delivery</h6>
                <p>
                  <i class="fa fa-clock-o" aria-hidden="true"></i> Your order will be closed in: 58
                  days 20 hours 59 minutes
                </p>
              </div>
              <div class="order-action">
                <a href="" class="btn-action btn-track">Track Order</a>
                <a href="" class="btn-action btn-confirm">Confirm &amp; Feedback</a>
                <a href="" class="btn-action btn-dispute">Dispute Order</a>
                <a href="" class="btn-action btn-cancel">Cancel Order</a>
              </div>
            </div>
          </div>
          <div class="order-section">
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Order ID: <b>88523836532315</b><br />
                Order time & date: <small>22:23 Dec. 06 2017</small>
              </div>
              <div class="order-ammount">Order amount: <strong>$35.00</strong></div>
              <div class="delete-order">
                <a href=""> <i class="fa fa-trash-o" aria-hidden="true"></i></a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img src="../assets/images/product-2.jpg" alt="" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>Beekeeping Smoker</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text.
                    </p>
                  </a>
                </div>
              </div>
              <div class="order-status">
                <h6>Awaiting delivery</h6>
                <p>
                  <i class="fa fa-clock-o" aria-hidden="true"></i> Your order will be closed in: 58
                  days 20 hours 59 minutes
                </p>
              </div>
              <div class="order-action">
                <a href="" class="btn-action btn-track">Track Order</a>
                <a href="" class="btn-action btn-confirm">Confirm &amp; Feedback</a>
                <a href="" class="btn-action btn-dispute">Dispute Order</a>
                <a href="" class="btn-action btn-cancel">Cancel Order</a>
              </div>
            </div>
          </div>
          <div class="order-section">
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Order ID: <b>88523836532315</b><br />
                Order time & date: <small>22:23 Dec. 06 2017</small>
              </div>
              <div class="order-ammount">Order amount: <strong>$35.00</strong></div>
              <div class="delete-order">
                <a href=""><i class="fa fa-trash-o" aria-hidden="true"></i></a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img src="../assets/images/product-3.jpg" alt="" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>Beekeeping Brash</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text.
                    </p>
                  </a>
                </div>
              </div>
              <div class="order-status">
                <h6>Awaiting delivery</h6>
                <p>
                  <i class="fa fa-clock-o" aria-hidden="true"></i> Your order will be closed in: 58
                  days 20 hours 59 minutes
                </p>
              </div>
              <div class="order-action">
                <a href="" class="btn-action btn-track">Track Order</a>
                <a href="" class="btn-action btn-confirm">Confirm &amp; Feedback</a>
                <a href="" class="btn-action btn-dispute">Dispute Order</a>
                <a href="" class="btn-action btn-cancel">Cancel Order</a>
              </div>
            </div>
          </div>
          <div class="order-section">
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Order ID: <b>88523836532315</b><br />
                Order time & date: <small>22:23 Dec. 06 2017</small>
              </div>
              <div class="order-ammount">Order amount: <strong>$35.00</strong></div>
              <div class="delete-order">
                <a href=""><i class="fa fa-trash-o" aria-hidden="true"></i></a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img src="../assets/images/product-4.jpg" alt="" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>Beekeeping Jacket</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text.
                    </p>
                  </a>
                </div>
              </div>
              <div class="order-status">
                <h6>Awaiting delivery</h6>
                <p>
                  <i class="fa fa-clock-o" aria-hidden="true"></i> Your order will be closed in: 58
                  days 20 hours 59 minutes
                </p>
              </div>
              <div class="order-action">
                <a href="" class="btn-action btn-track">Track Order</a>
                <a href="" class="btn-action btn-confirm">Confirm &amp; Feedback</a>
                <a href="" class="btn-action btn-dispute">Dispute Order</a>
                <a href="" class="btn-action btn-cancel"> Cancel Order</a>
              </div>
            </div>
          </div>
        </div>
        <ProfileNavigation />
      </div>
    </div>
  </div>
  <!-- My Order End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src
import { auth } from "@/includes/firebase";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import ProfileNavigation from "@/components/ProfileNavigation.vue";

export default {
  name: "Orders",
  components: {
    Breadcrumbs,
    Newsletter,
    ProfileNavigation,
  },
  methods: {
    signout() {
      auth
        .signOut()
        .then(() => {
          this.$router.replace("/");
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
  },
};
</script>
